
import {createRouter,createWebHashHistory}from 'vue-router'
// import {BasicLayout,} from '@/view/common/main/Layout'
// import { USER_MENU } from '@/utils/constants'

const router=createRouter({
    history:createWebHashHistory("http://192.168.0.88:8080/"),
    routes:[
        {
            path: '/',
            redirect:'/main',
        },
        {
            path: '/main',
            name:'mainHome',
            component: ()=>import('../view/main/main'),
        },
        {
            path: '/pro',
            name:'productData',
            component: ()=>import('../view/product/productData,'),
        },
        {
            path: '/success',
            name:'CompanySuccess',
            component: ()=>import('../view/success/CompanySuccess'),
        },

        {
            path:'/quality',
            name:'QualityControl',
            component: ()=>import('../view/quality/QualityControl'),
        },
        {
            path:'/aboutUs',
            name:'AboutUs',
            children:[
                {
                    path:'/aboutUs',
                    name:'AboutUs',
                    component: ()=>import('../view/aboutUs/AboutUs'),
                },
                {   name:'CompanyCulture',
                    path: '/companyCulture',
                    component: () => import('../view/aboutUs/CompanyCulture')
                },
                {name:'CompanyProfile', path: '/companyProfile', component: () => import('../view/aboutUs/CompanyProfile') },
                {name:'CompanyPublicize', path: '/companyPublicize', component: () => import('../view/aboutUs/CompanyPublicize') },
                {name:'ScienceTechnology', path: '/scienceTechnology', component: () => import('../view/aboutUs/ScienceTechnology') },
            ]
        },
        {
            path:'/chatUs',
            name:'ChatUs',
            component: ()=>import('../view/chatUs/ChatUs'),
        },

    ]
})
export default router
