<template>
    <a-layout class="layout" >
        <a-layout-header>
            <div style="height:100px; padding-top:1%;z-index: 99;width:100%; background-color: white; position:fixed;">
                    <!--                <div id="innerW" style="width:100px;height: 100px;border:1px solid red;">{{innerWidth}}</div>-->
                    <div style="width: 98%; display: inline-block;border:0px solid red; ">
                        <div class="logo" @click="$router.push('/')">
                            <img src="../../../assets/rdlogo.png">
                        </div>
                        <a-float-button tooltip="菜单" :style="{top: '16px',left:'50px'}" @click="toggleCollapsed" v-if="data.showCollapsed" >
                            <template #icon>
                                <MenuUnfoldOutlined v-if="data.collapsed"  />
                                <MenuFoldOutlined v-else/>
                            </template>
                        </a-float-button>
                        <a-drawer
                                v-model:open="data.collapsed"
                                class="custom-class"
                                root-class-name="root-class-name"
                                :root-style="{ color: 'blue' }"
                                width="120"
                                style="color: red; padding: 0;margin: 0;"
                                title="菜单"
                                placement="left"
                                @after-open-change="afterOpenChange"
                        >
                            <a-menu
                                    v-model:openKeys="openKeys"
                                    v-model:selectedKeys="data.items.key"
                                    mode="vertical"
                                    :inline-collapsed="data.collapsed"
                                    :items="data.items"
                                    @click="handleClick"
                                    style="display: inline-block;"
                            />
                        </a-drawer>
                        <a-menu
                                v-model:open="data.collapsed"
                                v-model:selectedKeys="data.items.key"
                                mode="horizontal"
                                :items="data.items"
                                @click="handleClick"
                                style="display: inline-block; width: 75%;"
                                v-if="!data.showCollapsed"
                        />
                        <div style="margin-top: 5px; margin-right: 4%;display: inline-block;  ">
                            <a href="https://www.rongded.com/en/" style="color: black;">English</a>|
                            <a >中文</a>
                        </div>
                    </div>
                </div>
            </a-layout-header>
        <a-layout-content>
            <div style="background-color: white;padding-top:40px;width: 100%; font-family: 'Alibaba Medium';padding-bottom: 50px; ">
                <router-view></router-view>
                <a-back-top/>
            </div>
        </a-layout-content>
        <a-layout-footer   v-if="!data.showCollapsed" style="width: 100%;">
            <div style="height: 300px;  border:0px solid red; text-align: center; background-color: #444444; color: white;display: flex;">
                <div style=" width: 70%;padding-left: 10%; padding-top:4%;margin: 0; border:0px solid  salmon;">
                    <a-row>
                        <h3 style="padding-right: 3%;">
                            导航
                        </h3>
                        <ul class="foot_nav">
                            <li @click="gotoNav('/')">网站首页</li>
                            |
                            <li @click="gotoNav('','YLProductLists')">产品介绍</li>
                            |
                            <li @click="gotoNav('/success')">客户成功案例</li>
                            |
                            <li @click="gotoNav('/quality')">质量保障</li>
                            |
                            <li @click="gotoNav('/aboutUs')">关于我们</li>
                            |
                            <li @click="gotoNav('/chatUs')">全球服务网点</li>
                        </ul>
                    </a-row>
                    <a-row>
                        <h3 style="padding-right: 3%;">
                            产品
                        </h3>
                        <ul class="foot_nav">
                            <li @click="gotoNav('','YLProductLists')">压力、温度、液位测量报警系统</li>
                            |
                            <li @click="gotoNav('','FMProductLists')">阀门遥控系统</li>
                            |
                            <li @click="gotoNav('','FFProductLists')">防腐防污系统</li>
                            |
                            <li @click="gotoNav('','XFProductLists')">消防系统</li>
                            |
<!--                            <li @click="gotoNav('','DQProductLists')">惰性气体系统</li>-->
<!--                            |-->
                            <li @click="gotoNav('','ZNProductLists')">智能监控报警系统</li>
                            |
                            <li @click="gotoNav('','CLProductLists')">磁力耦合器及永磁调速器</li>
                        </ul>
                    </a-row>
                    <a-row style="text-align: left;">
                        <a-col :span="6">
                            <h3>
                                联系电话: 021-68532791
                            </h3>
                        </a-col>
                        <a-col :span="7">
                            <h3 style="margin-right: 3vw;">
                                传真：021-58212204
                            </h3>
                        </a-col>
                        <a-col :span="11">
                            <h3 style="margin-right: 3vw;">
                                邮箱：sale@rongded.com
                            </h3>
                        </a-col>
                    </a-row>
                    <a-row style="text-align: left;">
                        <a-col :span="13">
                            <h3>
                                总部地址：中国上海市浦东大道1695弄1号1401/04室
                            </h3>
                        </a-col>
                        <a-col :span="11">
                            <h3>
                                生产地址：中国上海市奉贤区杨跃路268号
                            </h3>
                        </a-col>
                    </a-row>
                </div>
                <div style="width: 30%;display:inline-block;border:0px solid blue;position: relative; ">
                    <img :src="$publicPath+'/images/chat.png'"
                         style="height: 150px; width: 150px; position: absolute;top:20%;left:25%; "/>
                    <span style="position: absolute; top: 73%; left:29%;display:block; ">
                            扫一扫，关注我们
                        </span>
                </div>
            </div>
            <div style="width: 100%;line-height:50px; text-align: center; background-color: #131212; color: white;">
                ©2018 融德机电设备有限公司版权所有 |
                <a style="color: white;" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备05043000号-4</a> |
                <a style="color: white;"
                   href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502000653" target="_blank">
                    <img style="height: 20px;width: 20px;line-height: 20px;"
                         src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"/>沪公网安备 31011502000653</a>
            </div>
        </a-layout-footer>
    </a-layout>
</template>
<script setup>
    import {reactive,h, onMounted,ref,onUnmounted} from 'vue';
    import {
        HomeOutlined,
        MailOutlined,
        CalendarOutlined,
        AppstoreOutlined,
        TeamOutlined,
        GlobalOutlined
    } from '@ant-design/icons-vue';
    import enUS from 'ant-design-vue/es/locale/en_US';
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import {useRouter} from "vue-router";
    const $router = useRouter();
    let data= reactive({
        selectedKeys: '1',
        enUS,
        zhCN,
        collapsed: false,
        showCollapsed:false,
        locale: zhCN.locale,
        items: [
            {
                key: '0',
                icon: () => h(HomeOutlined),
                label: '首页',
                title: '首页',
                path: '/',
            },
            {
                key: '1',
                icon: () => h(MailOutlined),
                label: '产品介绍',
                title: 'YLProductLists',
                children: [
                    {
                        key: '6',
                        label: '压力、温度、液位测量报警系统',
                        title: 'YLProductLists',
                        path: '/pro',
                    },
                    {
                        key: '7',
                        label: '阀门遥控系统',
                        title: 'FMProductLists',
                        path: '/pro',
                    },
                    {
                        key: '8',
                        label: '防腐防污染系统',
                        title: 'FFProductLists',
                        path: '/pro',
                    },
                    {
                        key: '9',
                        label: '消防系统',
                        title: 'XFProductLists',
                        path: '/pro',
                    },
                    // {
                    //     key: '10',
                    //     label: '惰性气体系统',
                    //     title: 'DQProductLists',
                    //     path: '/pro',
                    // },
                    {
                        key: '11',
                        label: '智能监控报警系统',
                        title: 'ZNProductLists',
                        path: '/pro',
                    },
                    {
                        key: '12',
                        label: '磁力偶合器及永磁调速器',
                        title: 'CLProductLists',
                        path: '/pro',
                    },
                ],
            },
            {
                key: '2',
                icon: () => h(CalendarOutlined),
                label: '客户成功案例',
                title: '客户成功案例',
                path: '/success'

            },
            {
                key: '3',
                icon: () => h(AppstoreOutlined),
                label: '质量保障',
                title: '质量保障',
                path: '/quality'
            },
            {
                key: '4',
                icon: () => h(TeamOutlined),
                label: '关于我们',
                title: '关于我们',
                path: '/aboutUs',
            },
            {
                key: '5',
                icon: () => h(GlobalOutlined),
                label: '全球服务点',
                title: '全球服务点',
                path: '/chatUs'

            },
        ]
    });
    onMounted(() => {
        const debounce = (fn, delay) => {
            let timer = null;

            return function () {
                let context = this;

                let args = arguments;

                clearTimeout(timer);

                timer = setTimeout(function () {
                    fn.apply(context, args);
                }, delay);
            };
        };

        // 解决ERROR ResizeObserver loop completed with undelivered notifications.

        const _ResizeObserver = window.ResizeObserver;

        window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
            constructor(callback) {
                callback = debounce(callback, 20);
                super(callback);
            }
        };
        window.onresize = () => {
            var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
            var deviceHeight=document.documentElement.clientHeight;
            console.log("窗口大小：",devicewidth,"高",deviceHeight)
            if(devicewidth<1150){
                data.showCollapsed=true;
            }else {
                data.showCollapsed=false;
            }
            if(document.getElementById("main_img")){

                if(devicewidth*0.92/(1591/652)<290){
                    document.getElementById("main_img").style.height='280px'
                }else{
                    document.getElementById("main_img").style.height=devicewidth*0.92/(1591/652)+'px'

                }
            }



            return (() => {
                screenWidth.value = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            })()
        }

    })
    const handleClick=(item)=>{
        console.log(" this.$router.push(item.item.title:", item.item.title)
        $router.push({
            path: item.item.path,
            query: {title: item.item.title}
        });
    }
    const gotoNav=(path, title)=> {
        if (path != '') {
            $router.push({
                path: path,

            });
        } else {
            $router.push({
                path: '/pro',
                query: {title: title}
            });
        }
    }
    const toggleCollapsed=()=> {
        data.collapsed = !data.collapsed;
    }
    // const  handleResize=()=> {
    //     // 处理视口变化的逻辑
    //     var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
    //     // console.log("窗口大小：",devicewidth)
    // }

      const screenWidth = ref(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
     //使用防抖 (setTimeout定时器)
     //  const getWindowInfo = () => {
     //      const windowInfo = {
     //          width: window.innerWidth,
     //          hight: window.innerHeight
     //            }
     //        };
     //  const debounce = (fn, delay) => {
     //      let timer;
     //      return function() {
     //          if (timer) {
     //              clearTimeout(timer);
     //          }
     //          timer = setTimeout(() => {
     //              fn();
     //          }, delay);
     //      }
     //        };
       //触发事件    触发时间（指定时间内执行事件）
       //      const cancalDebounce = debounce(getWindowInfo, 500);
       //      window.addEventListener('resize', cancalDebounce);

            onUnmounted(() => {
                console.log('onUnmounted','打印是否生效');
                //移除监听事件
                // window.removeEventListener('resize', cancalDebounce);
            })
            // watch(() => screenWidth, (val) => {
            //     screenWidth.value = val
            // })

</script>
<style scoped type="less">
    @import url('../../../font/font.css');

    * {
        font-family: "Alibaba Medium";
    }

    .ant-layout-header {
        background-color: white;
    }

    .ant-menu-overflow {
        display: inline-block;
        font-family: "Alibaba Medium" !important;
    }

    .ant-layout-footer {
        padding: 0;
        margin: 0 auto;
    }

    .foot_nav {
        margin: 0;
        display: inline-block;
        padding: 0;
        list-style: none;
        font-family: "Alibaba Medium" !important;
    }

    li {
        display: inline-block;
        /*font-size:1rem;*/
        margin: 5px 5px 20px;
        cursor: pointer;
        font-family: "Alibaba Medium" !important;
    }

    .logo {
        display: inline-block;
        width: 100px;
        height: 150px;
        margin-right: 5vw;
        border: 0px solid salmon;
    }

    .ant-drawer-body {
        padding: 0px !important;
    }
</style>