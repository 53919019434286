import { createApp } from 'vue'
import App from './App.vue'
import Ant from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueRouter from './router/router.config'
import * as Icons from '@ant-design/icons-vue'
import  './css/global.less'
import '@/font/font.css'
import 'lib-flexible'
import '/public/rem'

function px2rem(px){
    if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
        return px
    }else{
        return (parseFloat(px) / 192) + 'rem' // 这里的37.5，和rootValue值对应
    }
}



const app=createApp(App);
const icons=Icons;
for (const i in icons){
app.component(i,icons[i]);
}
app.config.globalProperties.$publicPath="https://www.rongded.com/"
//app.config.globalProperties.$publicPath="http://192.168.0.88:8017"
app.config.globalProperties.$px2rem = px2rem // 放到全局
app.use(Ant).use(VueRouter).use(Icons).mount('#app')
